import React from "react";
import styles from "../styles/Header.module.scss";

const Header = () => {
  return (
    <div className={styles.header}>
      <a href='/' >
        <img
          className={styles.logo}
          src="https://frontend-assets.buildbox.com/0_BBWorld_New_Logo_horizontal_light.png"
          alt="buildbox"
        /> 
      </a>

    </div>
  );
};

export default Header;
