import React from "react";
import './App.css';
import styles from "./styles/App.module.scss";
import PageRoutes from "./PageRoutes";
import { AppInstallPrompt } from "./components/AppInstallPrompt";

function App() {
  return (
    <div className={styles.App} id="App">
      <PageRoutes />
      <AppInstallPrompt/>
    </div>
  );
}

export default App;
 