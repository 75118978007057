import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import Amplify from "@aws-amplify/core";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
// import { aws_config } from "./utils/constants/amplify_config";

// Amplify.configure({
//   Auth: {
//     mandatorySignIn: false,
//     region: aws_config.cognito.REGION,
//     userPoolId: aws_config.cognito.USER_POOL_ID,
//     userPoolWebClientId: aws_config.cognito.APP_CLIENT_ID,
//   },
//   API: {
//     endpoints: [
//       {
//         name: "bbworld",
//         endpoint: aws_config.buildboxworldGateway.URL,
//         region: aws_config.buildboxworldGateway.REGION,
//       }
//     ],
//   },
//   oauth: {
//     domain: aws_config.oauth.domain,
//     scope: aws_config.oauth.scope,
//     redirectSignIn: aws_config.oauth.redirectSignIn,
//     redirectSignOut: aws_config.oauth.redirectSignOut,
//     responseType: aws_config.oauth.responseType,
//   },
// });


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
