import React from 'react'

//TODO: Design for Loading bar or icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import styles from "../styles/LoadingSpinner.module.scss";

const LoadingSpinner = () => {
  return (
    <div className={styles.loadingContainer}>
      <FontAwesomeIcon size='8x' className={styles.loadingIcon } icon={faSpinner} spin/>
      <h1>
        Loading Bit...
      </h1>
    </div>
  )
}

export default LoadingSpinner
