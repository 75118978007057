import React, { useState } from "react"
import classnames from "classnames"
import { isAndroid, browserName } from "react-device-detect";
import Drawer from "antd/lib/drawer"

import "antd/dist/antd.css"

import styles from "../styles/AppInstallPrompt.module.scss";

function shouldShow() {
  // disabled
  return false
  // // only show on the main homepage
  // const pathname = window.location.pathname;
  // if(pathname == "/") {
  //   return isMobile
  // }
  // return false
}

export function AppInstallPrompt(props) {
  const [visible, setVisible] = useState(shouldShow())

  function dismiss() {
    setVisible(false)
  }

  function continueClicked() {
    dismiss();
  }

  function AppStoreButton () {
    if (isAndroid) {
      return (
        <a
          href="https://play.google.com/store/apps/details?id=com.apponboard.bbworld"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={classnames(styles.appstoreBtn)}
            src="https://frontend-assets.buildbox.com/GooglePlay.png"
            alt="Download Buildbox World Google Play Button"
          />
        </a>
      )
    }
    else {
      return (
        <a
          href="https://apps.apple.com/us/app/buildbox-world/id1544021219"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={classnames(styles.appstoreBtn)}
            src="https://frontend-assets.buildbox.com/AppStoreButton.png"
            alt="Download Buildbox World App Store"
          />
        </a>
      )
    }
  }

  let browserIconName = browserName.toLowerCase()
  // convert 'mobile safari' etc. to just safari
  if(browserIconName.includes("safari")) {
    browserIconName = "safari";
  }
  const browserIconUrl = `https://frontend-assets.buildbox.com/browser-logos/${browserIconName}/${browserIconName}.svg`;

  return (
    <Drawer visible={visible} closable={false} placement="bottom">
      <div className={styles.mainContainer}>
        <div className={styles.header}>
          <h1>Open Buildbox World in</h1>
        </div>

        <div className={styles.optionRow}>
          <img className={styles.icon} src="https://frontend-assets.buildbox.com/BBW_Icon.svg" alt="icon" />
          <p>Buildbox World</p>
          <div className={styles.buttonWrapper} >
            <AppStoreButton/>
          </div>
        </div>

        <div className={styles.optionRow}>
          <img className={styles.icon} src={browserIconUrl} alt="icon" />
          <p>{browserName}</p>
          {/* <button className={heroStyles.signupBtnMobile}>Continue</button> */}
          <div className={styles.buttonWrapper} onClick={continueClicked}>
            <img className={classnames(styles.appstoreBtn)} src="https://frontend-assets.buildbox.com/ContinueButton.png" alt="apple"/>
          </div>
        </div>        

      </div>
    </Drawer> 
  )
}

