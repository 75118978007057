import React, { useEffect, useState } from 'react'

import { isDesktop, isMobile } from 'react-device-detect';
import LoadingSpinner from '../../components/LoadingSpinner';
import styles from '../../styles/BitURLDownload.module.scss';
import { fetchBitInformation } from '../../api/bits';
import { contentText } from './sharedbitConstants';
import { useMediaQuery } from 'react-responsive';
import PageMeta from '../../components/PageMeta';
import Header from '../../components/Header';
import { useParams, useSearchParams } from 'react-router-dom';

// import footerDesktop from '../../images/BBW_footerimage_desktop.png';

const SharedBit = () => {
  const {id} = useParams();
  let [searchParams] = useSearchParams();

  const [bitInfo, setBitInfo ] = useState({
    displayName: "Bit Not found",
    thumbnailUrl: "https://bbworldbbdoccdn.dev.8cell.com/d8206cc2-4f73-4751-ad77-c6a679440452/d1d335c3-4fea-495d-b122-9a0d77d916d8.jpg",
    description: 'Click on the link to try out the bit!',
    plays: 0,
    likes: 0,
  });

  const [loading, setLoading] = useState(false);
  const [ errorBitInfo, setErrorBitInfo ] = useState(false);

  const appState = process.env.REACT_APP_STAGE === 'prod' ? 'https://bits.buildboxworld.com' : 'https://bits.buildboxworld.dev.8cell.com';

  const isWideWidth = useMediaQuery({
    query: '(min-width: 1025px)'
  })
  
  const setBitInfromtation = async () => {
    try{ 
      const bitId = id || searchParams.get("bit_id");
      setLoading(true);
      const response = await fetchBitInformation(bitId);
      setBitInfo(response);
      setLoading(false);
    }catch(err){
      console.log(err);
      setErrorBitInfo(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    setBitInfromtation();
    // eslint-disable-next-line
  }, [])

  const MobileRender = () => {
    return(
      <div className={styles.mobile}>
        <div className={styles.bitInfoMobile}>
          <h1 className={styles.titleText}>You’re missing out!</h1>
          {!errorBitInfo ?   <h2 className={styles.subTitleText}>{contentText.successTextMobile}</h2> :
          <h2 className={styles.subTitleText}>{contentText.errorQrTextMobile}</h2> 
          }
          <div className={styles.appButtons}>
            <div>
            <a
                href="https://play.google.com/store/apps/details?id=com.apponboard.bbworld"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.appstoreBtn}
                  src="https://frontend-assets.buildbox.com/GooglePlay.png"
                  alt="Buildbox World Google Play Button"
                />
              </a>
            </div>
          </div>
          <div className={styles.bitContent}>

            { loading ? <LoadingSpinner/> : <>
              <img className={styles.thumbnailUrl} src={ bitInfo.thumbnailUrl} alt='thumbnail' />
              <h1 className={styles.displayName}>{ bitInfo.displayName }</h1>
              { bitInfo.likes > 0 && <p>Likes: {bitInfo.likes}</p>}
              { bitInfo.plays >0 && <p>Plays: {bitInfo.plays}</p>}
              </>
            }
          </div>
        </div>
      </div>    
    )
  }

  console.log(id, searchParams.get('bit_id'))

  const DesktopRender = () => {
    return (
      <div className={styles.desktop}> 
      {
        isWideWidth ? 
              <div className={styles.bitInfo}>
              <div className={ styles.bitContent }>
                { loading ? <LoadingSpinner/> : <>
                  <img className={styles.thumbnailUrl} src={ bitInfo.thumbnailUrl} alt='thumbnail' />
                  <h1 className={styles.displayName}>{ bitInfo.displayName }</h1>
                  { bitInfo.likes > 0 && <p>Likes: {bitInfo.likes}</p>}
                  { bitInfo.plays >0 && <p>Plays: {bitInfo.plays}</p>}
                  </>
                }
              </div>
              {/* <div className={styles.verticalLine}/> */}
              <hr className={styles.verticalLine} />
              <div className={styles.qrContent}>
                <h1 className={styles.qrCodeTitle}>You’re missing out!</h1>
                <div className={styles.qrCode}>
                    <img src='https://frontend-assets.buildbox.com/BBW_QR_Code.svg' alt="buildboxworld-qr-code"/>
      
                  {!errorBitInfo ?   <h2 className={styles.qrCodeText}>{contentText.successText}</h2> :
                  <h2 className={styles.qrCodeText}>{contentText.errorQrTextg}</h2> 
                  }
                </div>
              </div>
            </div> :

          <div className={styles.bitInfo}>
            <div className={styles.qrContent}>
              <h1 className={styles.qrCodeTitle}>You’re missing out!</h1>
              <div className={styles.qrCode}>
                  <img src='https://frontend-assets.buildbox.com/BBW_QR_Code.svg' alt="buildboxworld-qr-code"/>

                {!errorBitInfo ?   <h2 className={styles.qrCodeText}>{contentText.successText}</h2> :
                <h2 className={styles.qrCodeText}>{contentText.errorQrTextg}</h2> 
                }
              </div>
            </div>
            <div className={ styles.bitContent }>
              { loading ? <LoadingSpinner/> : <>
                <img className={styles.thumbnailUrl} src={ bitInfo.thumbnailUrl} alt='thumbnail' />
                <h1 className={styles.displayName}>{ bitInfo.displayName }</h1>
                { bitInfo.likes > 0 && <p>Likes: {bitInfo.likes}</p>}
                { bitInfo.plays >0 && <p>Plays: {bitInfo.plays}</p>}
                </>
              }
            </div>
          </div>
      }

      </div> 
    )
  }

  // TODO: Need to figure out what is wrong with this preview link

  return (
    <>
      <PageMeta link={`${appState}/bits/${id}`} name={bitInfo.displayName} content={bitInfo.description} title={bitInfo.displayName} imgUrl={bitInfo.thumbnailUrl} />
      <Header/> 
      <div className={styles.container}>
        { isDesktop && <DesktopRender/>}
        { isMobile && <MobileRender/>} 
      </div>
    </> 
  )
}

export default SharedBit
 