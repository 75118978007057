import React from 'react'
import { Helmet } from 'react-helmet';

const PageMeta = (props) => {
  const { link, name, content, title, imgUrl } = props

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={link} />
      <title>{title}</title>
      <meta name="description" content={content}/>


      <meta itemprop="name" content={name}/>
      <meta itemprop="description" content={content}/>
      <meta itemprop="image" content={imgUrl}/>


      <meta property="og:url" content={link}/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={name}/>
      <meta property="og:description" content={content}/>
      <meta property="og:image" content={imgUrl}/>


      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={name}/>
      <meta name="twitter:description" content={content}/>
      <meta name="twitter:image" content={imgUrl}></meta>
    </Helmet>
  )
}

export default PageMeta