import React from "react";
import { Route, Routes } from "react-router-dom";
import SharedBit from './pages/sharedbit/SharedBit';
import routes from "./utils/constants/routes.json";

import NotFound from './pages/NotFound';

const RedirectRouteHome = () => {
  return  (
  <>
    {window.location.href ="https://buildboxworld.com/"}
  </>
  )
}

// const RedirectRoute = (props) => {
//   const {link} = props;
//   return  (
//   <>
//     {window.location.href =`${link}`}
//   </>
//   )
// }

export default function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RedirectRouteHome/>}/>
      <Route path={routes.SHARED_BIT} element={<SharedBit/>}/>
      <Route path={routes.SHARED_BIT_} element={<SharedBit/>}/>
      <Route element={ <NotFound />}/>
      
    </Routes>
  );
}