// created the endpoint here
// import API from "@aws-amplify/api";

import Axios from "axios";
/*
* Fetchs for the general bit information, likes, realtime data
*/

export const fetchBitInformation = (id) => {
  return new Promise( (resolve, reject) => {
    Axios.get(`${process.env.REACT_APP_BUILD_BOX_WORLD_GATEWAY_URL}/bbdoc/global/${id}`)
    .then((response) => {
      console.log(response.data);
      const { data } = response;
      // console.log(data.payload);
      resolve(data.payload.bitInfo);
    })
    .catch((err) => {
      console.log(err);
      if(err.reponse){
        console.log(err.response.status);
        console.log(err.response.data);
      }
      reject(err);
    });
  })
}

// TODO: Attempt to get this working
// export const fetchBitInformation = (id) => {
//   return new Promise( (resolve, reject) => {
//     API.get('bbworld',`bbdoc/global/${id}`,{
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       },
//       response: true,
//     })
//     .then((response) => {
//       const { data, status } = response;
//       console.log(data.payload);
//       resolve(data.payload.bitinfo);
//     })
//     .catch((err) => {
//       console.log(err);
//       if(err.reponse){
//         console.log(err.response.status);
//         console.log(err.response.data);
//       }
//       reject(err);
//     });
//   })
// }